import React, { useState, useEffect, useMemo, useRef } from 'react'
import './Character.scss'
import Modal from 'react-modal'
import { nanoid } from 'nanoid'
import Statblock from './components/Statblock'
import Conditions from './components/Conditions'

Modal.setAppElement('#root')

export default function Character (props) {
    const [data, setData] = useState(props.data); // TODO: remove need to copy props to state
    const [damageModalOpen, setDamageModalOpen] = useState(false);
    const [healModalOpen, setHealModalOpen] = useState(false);
    const [reminderModalOpen, setReminderModalOpen] = useState(false);
    const [counterModalOpen, setCounterModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [noteModalOpen, setNoteModalOpen] = useState(false);
    const [infoTextModalOpen, setInfoTextModalOpen] = useState(false);

    const noteRef = useRef(null);

    const updateData = (field, value, number=true) => {
        setData({
            ...data, [field]: number ? parseInt(value) : value
        })
    }

    useEffect(() => {
        props.onChange(data);
    }, [data])

    const closeModal = () => {
        setDamageModalOpen(false)
        setHealModalOpen(false)
    }

    const closeCharacterModal = () => {
        setReminderModalOpen(false);
    }

    const closeCounterModal = () => {
        setCounterModalOpen(false);
    }

    const closeNoteModal = () => {
        setNoteModalOpen(false);
    }

    const submitHPMod = (modType, modValue) => {
        if (modType === 'damage') {
            updateData('hp', data.hp - modValue)
            props.historyAppend({
                type: 'harm',
                params: {
                    target: props.data.name,
                    value: modValue
                }
            })
        } else {
            updateData('hp', data.hp + modValue)
            props.historyAppend({
                type: 'heal',
                params: {
                    target: props.data.name,
                    value: modValue
                }
            })
        }
        closeModal()
    }

    const submitNote = (e) => {
        e.preventDefault();
        const existing = data.singleNotes || []
        setData({
            ...data, singleNotes: [...existing, e.target[0].value]
        })
        setNoteModalOpen(false)
    }

    const saveReminders = (reminders) => {
        setData({
            ...data, reminders: reminders
        })
    }

    const saveCounters = (counters) => {
        setData({
            ...data, counters: counters
        });
    }

    const addReminder = (reminder) => {
        const reminders = [...data.reminders, reminder]
        saveReminders(reminders);
    }

    const addCounter = (name, slots) => {
        const existingCounters = props.data.counters ? props.data.counters : [];
        saveCounters([...existingCounters, {
            name: name,
            slots: Array.from({length: slots}, () => false)
        }])
    }

    const removeCounter = (index) => {
        const counters = data.counters;
        counters.splice(index, 1);
        setData({
            ...data, counters:counters
        })
    }

    const removeReminder = (id) => {
        saveReminders([...data.reminders.filter(reminder => reminder.id !== id)]) // TODO: fix this to be a hash of some sort
    }

    const removeNote = (index) => {
        const notes = data.singleNotes;
        notes.splice(index, 1);
        setData({
            ...data, singleNotes: notes
        })
    }

    useEffect(() => {
        const reminders = data.reminders.filter(reminder => reminder.afterValue - (props.round - reminder.startingRound) >= 0)
        saveReminders(reminders);
    }, [props.round])

    const toggleCounterSlot = (name, slotIndex) => {
        const newCounters = props.data.counters ? Array.from(props.data.counters) : [];
        for (let i = 0; i < newCounters.length; i++) {
            if (newCounters[i].name === name) {
                newCounters[i].slots[slotIndex] = !newCounters[i].slots[slotIndex]
            }
        }
        saveCounters(newCounters);
    }

    const relativeHealth = () => {
        if (data.maxHp === undefined || data.maxHp === 0) {
            return 0;
        }
        if (data.maxHp < data.hp) {
            return 100;
        }
        return (data.hp / data.maxHp) * 100;
    }

    const mixedColor = (percentage) => {
        const color2 = {
            r: 75,
            g: 191,
            b: 151
        }

        const color1 = {
            r: 224,
            g: 100,
            b: 106
        }

        const colorMix = {
            r: Math.round(color1.r + (color2.r - color1.r) * (percentage / 100)),
            g: Math.round(color1.g + (color2.g - color1.g) * (percentage / 100)),
            b: Math.round(color1.b + (color2.b - color1.b) * (percentage / 100))
        }

        return `rgb(${colorMix.r}, ${colorMix.g}, ${colorMix.b})`
    }

    return (
        <div id={`Character-${props.data.id}`} ref={props.ref} className={`CharacterPill ${props.active ? 'Active' : ''}`}>
            <div className='OrderArea'>
                <div className='NameArea'>
                   <input onChange={(e) => updateData('name', e.target.value, false)} className='NameField' type='text' value={data.name} />
                </div>
                {data.hp !== undefined && data.maxHp !== undefined && data.maxHp !== 0 &&
                    <div style={
                        {
                            width: `${relativeHealth()}%`,
                            backgroundColor: mixedColor(relativeHealth())
                        }} className='Healthbar'>
                    </div>
                }
            </div>
            <div className='SubArea'>
                <div className='DataArea'>
                    <span className='Stats InitiativeArea'>
                        <label className=''>INIT</label><input onChange={(e) => updateData('initiative', e.target.value)} type='number' min='1' max='40' value={data.initiative} />
                    </span>
                    <span className='Stats ACArea'>
                        <label className=''>AC 🛡</label><input onChange={(e) => updateData('ac', e.target.value)} type='number' min='1' max='40' value={data.ac} />
                    </span>
                    <span className='Stats HPArea'>
                        <label className=''>MAX ♥</label><input onChange={(e) => updateData('maxHp', e.target.value)} type='number' min='0' value={data.maxHp} />
                    </span>
                    <span className='Stats HPArea'>
                        <label>CUR ♥</label><input onChange={(e) => updateData('hp', e.target.value)} type='number' min='0' value={data.hp} />
                        {props.commence && (
                            <span className='HPActions'>
                                <button className='Damage' onClick={() => setDamageModalOpen(true)}><span className='fa-solid fa-minus' /></button>
                                <button className='Heal' onClick={() => setHealModalOpen(true)}><span className='fa-solid fa-plus' /></button>
                            </span>
                        )}
                    </span>
                </div>
                <div className={`SmallActions ${props.active ? 'Active' : ''}`}>
                    <span className='ActionWrapper'><span className='ActionText'>Delete</span><button onClick={(e) => setDeleteModalOpen(true)} type='button'><span className='fa-solid fa-trash'/></button></span>
                    <span className='ActionWrapper'><span className='ActionText'>Copy</span><button onClick={(e) => props.duplicateCharacter(props.data.id)} type='button'><span className='fa-solid fa-copy'/></button></span>
                    <span className='ActionWrapper'><span className='ActionText'>Counter</span><button onClick={(e) => setCounterModalOpen(true)} type='button'><span className='fa-solid fa-circle-half-stroke'/></button></span>
                    <span className='ActionWrapper'><span className='ActionText'>Reminder</span><button onClick={(e) => setReminderModalOpen(true)} type='button'><span className='fa-solid fa-bell'/></button></span>
                    <span className='ActionWrapper'><span className='ActionText'>Note</span><button onClick={(e) => setNoteModalOpen(true)} type='button'><span className='fa-solid fa-comment-dots'/></button></span>
                    <span className='ActionWrapper'><span className='ActionText'>Stablock</span><button onClick={(e) => setInfoTextModalOpen(true)} type='button'><span className='fa-solid fa-table'/></button></span>
                </div>
            </div>
            <span>
                <table>
                    {data.singleNotes && data.singleNotes.map((note, index) => (
                        <tr key={index}>
                            <td><strong>{note}</strong></td>
                            <td><button onClick={(e) => removeNote(index)}><span className='fa fa-times'/></button></td>
                        </tr>
                    ))}
                </table>
                <table>
                    {data.reminders.map(reminder => (
                        <tr key={reminder.id} className={`${reminder.afterValue - (props.round - reminder.startingRound) === 0 ? 'ReminderNow': ''}`}>
                            <td><strong>{reminder.name}:</strong></td>
                            <td>After {reminder.afterValue - (props.round - reminder.startingRound)} Rounds</td>
                            <td>({reminder.trigger})</td>
                            <td><button onClick={(e) => removeReminder(reminder.id)}><span className='fa fa-times'/></button></td>
                        </tr>
                    ))}
                </table>
                <table>
                    {props.data.counters && props.data.counters.map((counter, counterIndex) => (
                        <tr key={counter.name}>
                            <td><strong>{counter.name}</strong></td>
                            <td>{counter.slots.map((slot, slotIndex) => (
                                <span onClick={() => toggleCounterSlot(counter.name, slotIndex)} className={`${slot === false ? 'fa-regular' : 'fa-solid'} fa-circle`}></span>
                            ))}</td>
                            <td><button onClick={(e) => removeCounter(counterIndex)}><span className='fa fa-times'/></button></td>
                        </tr>
                    ))}
                </table>
            </span>
            <Modal className='CharacterModal'  isOpen={deleteModalOpen}>
                <p>Delete {props.data.name}?</p>
                <div className='ActionArea'>
                    <button onClick={(e) => setDeleteModalOpen(false)}>No</button><button className='Confirm' onClick={(e) => props.deleteCharacter(props.data.id)}>Yes</button>
                </div>
            </Modal>
            <Modal className='CharacterModal' isOpen={noteModalOpen} onRequestClose={closeNoteModal}>
                <form onSubmit={submitNote} className='ReminderForm'>
                    <div className='Content'>
                        <div className='Inputs'>
                        <span>
                        <h1>Note for {data.name}</h1><br />
                        <input ref={noteRef} name='Note'/>
                    </span>

                        </div>
                        <div className='Templates'>
                            <Conditions cb={(val) => noteRef.current.value=val}/>
                        </div>
                    </div>
                    <div className='ActionArea'>
                        <button type='button' onClick={closeNoteModal} className='Cancel'>Cancel</button><button type='submit' className='Confirm'>Confirm</button>
                    </div>
                </form>
            </Modal>
            <Modal className='CharacterModal Widest Scrollable'  isOpen={infoTextModalOpen} onRequestClose={() => setInfoTextModalOpen(false)}>
                <Statblock value={data.statblock || ''} onUpdate={content => updateData('statblock', content, false)} />
            </Modal>
            <HPModModal mode={damageModalOpen ? 'damage' : 'heal'} isOpen={damageModalOpen || healModalOpen} close={closeModal} submitHPMod={submitHPMod}/>
            <ReminderModal round={props.round} open={reminderModalOpen} closeModal={closeCharacterModal} characterName={data.name} addReminder={addReminder} />
            <CounterModal open={counterModalOpen} closeModal={closeCounterModal} characterName={data.name} addCounter={addCounter}/>
        </div>
    );
}

const HPModModal = (props) => {
    const [modValue, setModValue] = useState(0);

    const submit = (full=true) => {
        try {
            if (modValue) {
                const intModVal = parseInt(modValue);
                props.submitHPMod(props.mode, full ? intModVal : Math.floor(intModVal/2));
            }
        } catch (e) {
            console.log("Invalid mod value")
        }
    }

    return (
        <Modal className='CharacterModal' overlayClassName={`OverlayCharacterModal ${props.mode ? 'damage' : 'heal'}`} isOpen={props.isOpen} onRequestClose={props.close}>
            <span>
                <strong>
                    {props.mode === 'damage' && <span className='DamageLabel'>Damage</span>}
                    {props.mode === 'heal' && <span className='HealLabel'>Heal</span>}
                </strong>
                {' '}{props.name}
            </span> for <input name='HpMod' type='number' value={modValue} onChange={(e) => setModValue(e.currentTarget.value)}/>
            <br/>
            <div className='ActionArea'>
                <button type='button' onClick={props.close} className='Cancel'>Cancel</button>
                {props.mode === 'heal' ?
                    <button onClick={submit} type='submit' className='Confirm Heal'>Heal ({modValue})</button>
                    :
                    <React.Fragment>
                        <button onClick={(e) => {submit(false)}} type='submit' className='Confirm DamageHalf'>Halved ({Math.floor(modValue/2)})</button>
                        <button onClick={submit} type='submit' className='Confirm DamageFull'>Full ({modValue})</button>
                    </React.Fragment>
                }
            </div>
        </Modal>
    )
}

const ReminderModal = (props) => {
    const [mValue, setMValue] = useState(1)
    const [measurement, setMeasurement] = useState('rounds')
    const [reminderName, setReminderName] = useState('')
    const [trigger, setTrigger] = useState('start')

    const updateRounds = (e) => {
        setMValue(e.target.value)
    }

    const updateMeasurement = (e) => {
        setMeasurement(e.target.value)
    }

    const updateTrigger = (e) => {
        setTrigger(e.target.value)
    }

    const updateReminderName = (e) => {
        setReminderName(e.target.value)
    }

    const setPreset = (e) => {
        const preset = e.target.innerText
        switch (preset) {
            case "1R":
                setMValue(1)
                setMeasurement('rounds')
                break
            case "1M":
                setMValue(1)
                setMeasurement('minutes')
                break
            case "10M":
                setMValue(10)
                setMeasurement('minutes')
                break
            default:
                setMValue(1)
                setMeasurement('rounds')
                break
        }
    }

    const submitReminder = (e) => {
        e.preventDefault()
        props.closeModal()
        props.addReminder({
            id: nanoid(),
            name: reminderName,
            afterValue: measurement === 'rounds' ? mValue : mValue * 10,
            trigger: trigger,
            startingRound: props.round
        })
    }

    return (
        <Modal className='CharacterModal Wider' isOpen={props.open}>
            <form onSubmit={submitReminder} className='ReminderForm'>
                <div className='Content'>
                    <div className='Inputs'>
                        <h1>Reminder for {props.characterName}</h1><br />
                        <span>Name: <input type='text' value={reminderName} onChange={updateReminderName} /></span><br />
                        <span>After: <input type='number' value={mValue} onChange={updateRounds} style={{ width: "40px" }} />
                            <select onChange={updateMeasurement} value={measurement}>
                                <option value='rounds'>Rounds</option>
                                <option value='minutes'>Minutes</option>
                            </select>
                            <span className='ValuePresets'>
                                <button type='button' onClick={setPreset}>1R</button><button type='button' onClick={setPreset}>1M</button><button type='button' onClick={setPreset}>10M</button>
                            </span>
                        </span><br />
                        <span>At the
                            <select value={trigger} onChange={updateTrigger}>
                                <option value='start'>Start of Turn</option>
                                <option value='end'>End of Turn</option>
                            </select>
                        </span>

                    </div>
                    <div className='Templates'>
                        <Conditions cb={setReminderName} />
                    </div>

                </div>
                <div className='ActionArea'>
                    <button type='button' onClick={props.closeModal} className='Cancel'>Cancel</button><button type='submit' className='Confirm'>Confirm</button>
                </div>
            </form>
        </Modal>
    )
}

const CounterModal = (props) => {

    const [name, setName] = useState('');
    const [slots, setSlots] = useState(3);

    const previewCounter = useMemo(() => {
        return Array.from({length: slots});
    }, [slots])

    const updateName = (e) => {
        setName(e.target.value);
    }

    const updateSlots = (e) => {
        setSlots(parseInt(e.target.value));
    }

    const submitCounter = (e) => {
        e.preventDefault()
        props.closeModal()
        props.addCounter(name, slots)
    }

    return (
        <Modal className='CharacterModal Wider' isOpen={props.open}>
            <form onSubmit={submitCounter}>
                <h1>Counter for {props.characterName}</h1>
                <span>Name: <input type='text' onChange={updateName} value={name}/></span><br/>
                <span>Slots: <input min={1} type='number' onChange={updateSlots} value={slots}/></span><br/>
                <h3>Preview</h3>
                <span className='CounterPreview'>
                    <span>{name}</span>{' '}
                    {previewCounter.map(counter => (<span className='fa-regular fa-circle'></span>))}
                </span>
                <div className='ActionArea'>
                    <button type='button' onClick={props.closeModal} className='Cancel'>Cancel</button><button type='submit' className='Confirm'>Confirm</button>
                </div>
            </form>
        </Modal>
    )
}

const OrderText = (props) => {
    if (props.order % 10 === 1 && props.order % 100 !== 11) {
        return `${props.order}st`
    } else if (props.order % 10 === 2 && props.order % 100 !== 12) {
        return `${props.order}nd`
    } else if (props.order % 10 === 3 && props.order % 100 !== 13) {
        return `${props.order}rd`
    } else {
        return `${props.order}th`
    }
}