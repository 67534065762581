import React from "react";

export default function Conditions(props) {
    return (
        <>
            {
                [
                    "blnd",  // Blinded
                    "chrm",  // Charmed
                    "dfnd",  // Deafened
                    "frgt",  // Frightened
                    "grpl",  // Grappled
                    "incap", // Incapacitated
                    "inv",   // Invisible
                    "par",   // Paralyzed
                    "petr",  // Petrified
                    "pois",  // Poisoned
                    "prn",   // Prone
                    "restr", // Restrained
                    "stun",  // Stunned
                    "unc",   // Unconscious
                ].map(cond => <button onClick={() => props.cb(cond.toUpperCase())} type='button'>{cond.toUpperCase()}</button>)
            }
        </>
    )
}